<template>
  <div>
    <div class="card card-custom gutter-b">
      <!--begin::Card header-->
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Liste des projets</h3>
        </div>
        <div class="card-toolbar">
          <a class="btn btn-icon btn-sm btn-primary mr-1"  href="/#/projet/ajout">
            <i class="icon-md fas fa-plus"></i>
          </a>
        </div>
      </div>
      <!--end::Card header-->
      <!--begin::Card body-->
      <div class="card-body">
        <b-table striped hover ref="table" :items="datatable.data" :fields="fields" @sort-changed="sortChanged">
          <template #cell(id)="data">
            <a :href="'/#/projet/detail/'+data.value">{{ data.value }}</a>
          </template>
          <template #cell(info.url)="data">
            <a :href=data.value>{{ data.value }}</a>
          </template>
        </b-table>
        <b-pagination size="lg" align="center" v-model="datatable.currentPage" @change="changePage" :total-rows="datatable.totalRow" :per-page="datatable.limitPage" aria-controls="itemList"></b-pagination>
      </div>
      <!--begin::Card body-->
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  data(){
    return {
      items: [],
      fields: [
        {
          key: 'id',
          label: 'ID',
          sortable:true
        },
        {
          key: 'nom',
          label: 'Nom'
        },
        {
          key: 'info.url',
          label: 'Site'
        }
      ],
      datatable:{
        currentPage: 1,
        nextPage: 2,
        previousPage: 0,
        limitPage: 20,
        totalRow: 0,
        data: []
      },
      sort:{
        field: 'id',
        value: 'DESC'
      },
      searchQuery:null
    };
  },
  components: {
  },
  watch: {
  },
  methods: {
    datatableInit: function(){
      var the = this
      this.$store.back.ajax('/projet/liste?'+this.query,null,(res) => {
        the.datatable = res;
      });
    },
    sortChanged(e){
      this.sort = {
        field: e.sortBy,
        value: e.sortDesc === true ? "DESC" : "ASC"
      };
      //this.datatable.data = [];
      this.datatableInit();
    },
    changePage(e){
      this.datatable.currentPage = e;
      this.datatableInit();
    }
  },
  computed: {
    query: function(){
      var sort = this.sort.field === null ? null : encodeURI(JSON.stringify(this.sort));
      return 'page='+this.datatable.currentPage+'&limit='+this.datatable.limitPage+'&sort='+sort+'&search='+this.searchQuery;
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vue Bootstrap", route: "alert" },
      { title: "" }
    ]);
    this.datatableInit()
  }
};
</script>
